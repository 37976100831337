.green-pill-wrapper {
  /* position: fixed; */
}

.green-pill {
  margin-top: 20px;
  min-height: 40px;
}
.green-pill-custom-content.green-pill-success > .anticon {
  display: none !important;
}
/* .green-pill-notice-content::before {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  filter: blur(10px);
} */
.green-pill-notice-content {
  background-color: #eeeeee !important;
  border-radius: 100px !important;
  /* color: white; */
  font-weight: bold;
  font-size: larger;
  min-width: min(500px, 99vw);
  padding: 1em !important;
  color: black;
  opacity: 0.92;
  backdrop-filter: blur(10px);
  /* filter: blur(10px); */
  /* min-height: 3em; */
}

.opacity-on-hover {
  opacity: 0.25;
}
.opacity-on-hover:hover {
  opacity: 1 !important;
}

.ant-menu-overflow-item
  .ant-menu-overflow-item-rest
  .ant-menu-submenu
  .ant-menu-submenu-horizontal {
  height: 40px;
}

.ant-upload-wrapper.ant-upload-picture-card-wrapper
  .ant-upload.ant-upload-select {
  border: none !important;
  background: none;
}

.ant-upload-wrapper.ant-upload-picture-circle-wrapper
  .ant-upload.ant-upload-select {
  border: none !important;
  background: none;
}

.ant-upload-list-item-container {
  height: 134px !important;
  width: 134px !important;
}

.ant-upload-list-item-container > .ant-upload-list-item {
  padding: 0 !important;
}

.ant-upload-list-item-container > .ant-upload-list-item::before {
  height: 100% !important;
  width: 100% !important;
}

.nl-embed-container-preview {
  align-items: center;
  width: 100%;
}

.nl-embed-preview-content {
  iframe {
    width: 100%;
    border-radius: 8px;
    border: none;
    height: auto !important;
    aspect-ratio: 1.67;
  }
}

.PhoneInput {
  display: flex !important;
}

.PhoneInputInput,
.PhoneInputInput:focus,
.PhoneInputInput:active,
.PhoneInputInput:hover,
.PhoneInputInput:focus-visible {
  border: none;
  box-shadow: none;
  outline: none;
  height: 100%;
  font-size: 14px !important;
  text-align: center;
  padding: 10px 26px !important;
  transition: all 200ms ease;
  color: silver !important;
  width: 100%;
  border-radius: 18px !important;
}

.inputWrapper {
  font-family: "NewfoundationWhyte-Regular";
  font-weight: 300;
  border-radius: 18px !important;
  height: 50px;
  border: silver 1px solid;
  display: flex;
  justify-content: center;
}

a.userchip-wrapper:link,
a.userchip-wrapper:hover,
a.userchip-wrapper:visited,
a.userchip-wrapper:active {
  text-decoration: none;
  width: 100%;
}

.flyaway-component {
  z-index: 9999;
  position: absolute;
  font-size: 50px;
  animation: flyaway 2s ease-out forwards;
}

@keyframes flyaway {
  0% {
    opacity: 1;
    transform: translate(0, 0);
  }
  100% {
    opacity: 0;
    transform: translate(
      calc(100px * cos(var(--angle))),
      calc(-100px * sin(var(--angle)))
    );
  }
}
.notifications-main .rnc__notification:not(:first-child) {
  /* top: -60px;
  position: relative; */
  /* margin-top: -60px; */
}

/* .notifications-main .rnc__notification:nth-child(-n+3) {
  display: none;
  background: green;
} */

.notifications-main .rnc__notification:not(:nth-child(-n + 3)) {
  /* background: red; */
  display: none;
}

@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.05;
  }
  100% {
    opacity: 1;
  }
}
.blink {
  animation: blink ease-in-out 0.9s infinite;
}

.folder-chat-content-item-wrapper a {
  text-decoration: underline;
}

.chat-powerup-btn,
.chat-powerup-btn:hover {
  height: 23px;
  width: 23px;
  border-radius: 6px;
  border: 1px solid rgba(235, 235, 235, 1) !important;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
}

.chat-powerup-btn:active {
  background-color: rgba(224, 253, 140);
  border-color: rgba(224, 253, 140, 1) !important;
}

q:before,
q:after {
  content: "";
}

.interweave-container,
.interweave-container > p {
  height: 100% !important;
  width: 100% !important;
}

.chat-quote {
  width: 100%;
  height: 100%;
  background-color: rgba(224, 252, 139, 0.7);
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: left;
  overflow: hidden;
  gap: 10px;
  padding: 20px;
}

.folder-chat-content-item-wrapper .chat-quote {
  max-width: 350px;
  border-radius: 18px;
}

.chat-quote-description {
  text-align: left;
}

.chat-quote a {
  font-size: 10px !important;
  font-family: "NewfoundationWhyte-Medium" !important;
  text-decoration: none;
}

.chat-quote span:not(.chat-quote-description) {
  font-size: 10px !important;
  font-family: "NewfoundationWhyte-Book" !important;
  background-color: transparent !important;
}

.chat-quote span a {
  text-decoration: underline;
}

.chat-quote cite {
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
}

.appear-half-second {
  opacity: 0;
  transition-delay: .3s;
  transition: opacity ease-in-out .2s;
}

.appear-half-second .show {
  opacity: 1;
}
