/* Font Face */
/* // WorkSans */
@font-face {
  font-family: "WorkSansLight";
  src:
    local("WorkSansLight"),
    url(./work_sans//WorkSans-Light.ttf) format("truetype");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "WorkSansRegular";
  src:
    local("WorkSansRegular"),
    url(./work_sans//WorkSans-Regular.ttf) format("truetype");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "WorkSansMedium";
  src:
    local("WorkSansMedium"),
    url(./work_sans//WorkSans-Medium.ttf) format("truetype");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "WorkSansBold";
  src:
    local("WorkSansBold"),
    url(./work_sans//WorkSans-Bold.ttf) format("truetype");
  font-weight: 700;
  font-style: normal;
}
/* // Favorit */
@font-face {
  font-family: "FavoritLight";
  src:
    local("FavoritLight"),
    url(./favorit/Favorit-Light.otf) format("opentype");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "FavoritRegular";
  src:
    local("FavoritRegular"),
    url(./favorit/Favorit-Regular.otf) format("opentype");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "FavoritMedium";
  src:
    local("FavoritMedium"),
    url(./favorit/Favorit-Medium.otf) format("opentype");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "FavoritBold";
  src:
    local("FavoritBold"),
    url(./favorit/Favorit-Bold.otf) format("opentype");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "ABCFavoritProBold";
  src:
    local("ABCFavoritProBold"),
    url(./ABC_Favorit_Pro_Full_Web/ABCFavorit-Bold.woff) format("woff");
  font-weight: 700;
  font-style: bold;
}
@font-face {
  font-family: "ABCFavoritProRegular";
  src:
    local("ABCFavoritProRegular"),
    url(./ABC_Favorit_Pro_Full_Web/ABCFavorit-Regular.woff) format("woff");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "ABCFavoritProMedium";
  src:
    local("ABCFavoritProMedium"),
    url(./ABC_Favorit_Pro_Full_Web/ABCFavorit-Medium.woff) format("woff");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "FavorLining";
  src:
    local("FavorLining"),
    url(./FavorLining/FavoritLining-Bold.otf) format("opentype");
  font-weight: 700;
  font-style: bold;
}

@font-face {
  font-family: "NewfoundationWhyte-Regular";
  src: local("NewfoundationWhyte"),
    url("./NewfoundationWhyte/Regular/NewfoundationWhyte-Regular.ttf");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "NewfoundationWhyte-Medium";
  src: local("NewfoundationWhyte"),
    url("./NewfoundationWhyte/Medium/NewfoundationWhyte-Medium.ttf");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "NewfoundationWhyte-Light";
  src: local("NewfoundationWhyte"),
    url("./NewfoundationWhyte/Light/NewfoundationWhyte-Light.ttf");
  font-weight: 100;
  font-style: normal;
}
@font-face {
  font-family: "NewfoundationWhyte-Book";
  src: local("NewfoundationWhyte"),
    url("./NewfoundationWhyte/Book/NewfoundationWhyte-Book.ttf");
  font-weight: 200;
  font-style: normal;
}
@font-face {
  font-family: "NewfoundationWhyte-Bold";
  src: local("NewfoundationWhyte"),
    url("./NewfoundationWhyte/Bold/NewfoundationWhyte-Bold.ttf");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Viafont";
  src: local("Viafont"), url("./Viafont/ABCViafont-Regular-Trial.otf");
}

@font-face {
  font-family: Romie-Regular;
  src: url("../fonts/Romie/Romie-Regular.ttf");
}

:root {
  font-family: "NewfoundationWhyte-Regular", serif !important;
}

body {
  font-family: "NewfoundationWhyte-Regular", Helvetica, Arial, sans-serif !important;
}
