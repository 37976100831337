/* scrollbars */
* {
  scrollbar-width: thin;
  scrollbar-color: lightgrey #ffffff;
  font-family: "NewfoundationWhyte-Regular", sans-serif;
  font-weight: 300;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 11px;
}

*::-webkit-scrollbar-track {
  background: #ffffff;
}

*::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  border: 3px solid #ffffff;
}

.cs-overlay {
  background-color: #eeff8367;
}

.scrollbar-container.cs-message-list__scroll-wrapper.ps.ps--active-y .ps__rail-y .ps__thumb-y,
.scrollbar-container.cs-message-list__scroll-wrapper.ps.ps--active-y .ps__rail-y .ps__thumb-y:hover,
.scrollbar-container.cs-message-list__scroll-wrapper.ps.ps--active-y .ps__rail-y:hover .ps__rail-y:hover {
  background-color: rgba(0, 0, 0, 0.25);
  scroll-behavior: smooth;
}

/* end of scrollbars */

body {
  font-size: 14px !important;
  overflow-x: hidden;
  background: white;
  /* background-color: aqua; */
}

html,
body {
  overscroll-behavior: none;
}

p {
  margin: 0 !important;
}

.header {
  background: white;
  color: black !important;
}

.site-layout {
  width: 100%;
}

.ant-layout .ant-layout-content aside {
  margin-top: 0 !important;
}

.root-layout {
  display: grid;
  grid-template-columns: 40px 1fr 300px;
  grid-template-rows: 40px 1fr 40px;
  grid-column-gap: 10px;
  grid-row-gap: 10px;
}

.layout-root>.container>aside {
  margin: 0 !important;
}

.root-layout>.center {
  grid-area: 2 / 2 / 3 / 3;
}

.root-layout>.div2 {
  grid-area: 1 / 2 / 2 / 3;
}

.root-layout>.div3 {
  grid-area: 2 / 1 / 3 / 2;
}

.root-layout>.div4 {
  grid-area: 1 / 1 / 2 / 2;
}

.root-layout>.div5 {
  grid-area: 3 / 1 / 4 / 2;
}

.root-layout>.div6 {
  grid-area: 3 / 2 / 4 / 3;
}

.root-layout>.div7 {
  grid-area: 3 / 3 / 4 / 4;
}

.root-layout>.div8 {
  grid-area: 2 / 3 / 3 / 4;
}

.root-layout>.div9 {
  grid-area: 1 / 3 / 2 / 4;
}

.ant-image {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* @media (min-width: 992px) {
  .ant-image-img,
  .ant-image {
    height: 100% !important;
  }
} */

.main-menu li.ant-menu-item {
  padding: 0;
  margin: 0;

  justify-content: center;
  display: flex !important;
  flex-direction: row;
  align-items: center;
  width: 53px;
  height: 45px;
}

.main-menu li.ant-menu-item-expanded,
.ant-menu-item-expanded-logo {
  justify-content: left !important;
  gap: 6px;
  border-radius: 15px;
  background-color: rgb(255, 255, 255) !important;
  max-width: 185px;
  width: 185px;
  height: 45px;
  padding: 6px !important;
  width: 100% !important;
}

.main-menu li.ant-menu-item-expanded:hover {
  background-color: rgb(245, 245, 245) !important;
}

.ant-menu-item-expanded-logo:hover {
  background-color: rgb(255, 255, 255) !important;
  width: 100%;
}

.ant-menu::before {
  display: none;
}

.ant-menu-item-group-title {
  padding: 0 !important;
}

.main-menu li.ant-menu-item:not(:first-child) {
  color: #000000;
  display: block;
  text-align: center;
  /* margin-top: -3px; */
}

.folder-chat-input:focus,
.folder-chat-input:focus-within {
  border: 1px solid black !important;
}

.folder-agent-dropdown {
  border-radius: 18px;
  background-color: white;
  box-shadow:
    0 6px 16px 0 rgba(0, 0, 0, 0.08),
    0 3px 6px -4px rgba(0, 0, 0, 0.12),
    0 9px 28px 8px rgba(0, 0, 0, 0.05);
  padding: 10px;
}

.folder-agent-dropdown>.ant-dropdown-menu {
  padding: 0 !important;
  border-radius: 0 !important;
}

.folder-agent-dropdown-separator {
  margin: 0 !important;
}

.make-public-btn:hover {
  background-color: rgba(217, 217, 217, 0.7) !important;
  border-color: transparent !important;
}

.main-menu-dropdown.ant-dropdown-menu.ant-dropdown-menu-vertical {
  margin-top: -5px;
}

.main-menu-dropdown.ant-dropdown-menu.ant-dropdown-menu-vertical>li.ant-dropdown-menu-item {
  padding: 5px 0 5px 5px !important;
  margin-left: 2px;
}

.main-menu li.ant-menu-item.ant-menu-item-selected {
  color: black;
}

.main-menu li {
  background: none !important;
}

@media screen and (min-width: 1024px) {
  .main-menu {
    border-right: lightgrey 1px solid;
  }

  .main-menu .ant-menu-item {
    height: 100%;
    width: 100%;
  }
}

@media screen and (max-width: 991px) {
  .ant-menu-light {
    border-radius: 18px;
    background: rgba(255, 255, 255, 0.6);
    backdrop-filter: blur(15px);
    -webkit-backdrop-filter: blur(15px);
  }

  .main-menu li.ant-menu-item {
    width: 32px !important;
    height: 32px !important;
  }

  .prompt-suggestion {
    flex: 1 1 100%;
    max-width: 100%;
  }
}

.ant-menu-root.ant-menu-vertical {
  border-inline-end: none !important;
}

.ant-menu-title-content {
  font-size: 12px;
  margin: 0px !important;
}

.text-center {
  text-align: center;
}

.text-small {
  font-size: 0.8em;
}

.text-large {
  font-size: 1.6em;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-justify {
  text-align: justify;
}

/* .folder-description { */
/* font-style: italic; */
/* } */

.secondary-color {
  color: #bababa;
}

* {
  /* font-size: inherit; */
  font-feature-settings: normal;
  font-variant: normal;
}

.ant-btn.full-width {
  width: 100% !important;
}

.ant-btn-default:not(:disabled):hover {
  transition: 150ms linear border;
}

.ant-btn-default:not(:disabled) {
  transition: 300ms linear border;
}

.ant-avatar {
  background: none;
  border: none;
}

.bold {
  font-weight: bold;
}

.drawer-toggle {
  border: 0;
  box-shadow: none;
  margin-left: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 0;
  height: 22px;
}

.ant-menu.ant-menu-horizontal.main-menu>.ant-menu-item {
  padding-inline: 6px !important;
}

/* .ant-menu > .ant-menu-item:hover::after,
.ant-menu > .ant-menu-submenu:hover::after,
.ant-menu > .ant-menu-item-active,
.ant-menu> .ant-menu-submenu-active,
.ant-menu > .ant-menu-item-open,
.ant-menu> .ant-menu-submenu-open,
.ant-menu > .ant-menu-item-selected,
.ant-menu > .ant-menu-submenu-selected {
  color: red;
  border-bottom: 2px solid red;
} */

.rc-autoresponsive-container {
  margin-left: auto;
  margin-right: auto;
}

.item-grid-item {
  background: green;
}

.post-widget-card.ant-card .ant-card-body {
  padding: 3px;
  border: 0;
}

.post-widget-card.ant-card:not(.ant-card-bordered) {
  border: 0;
  box-shadow: none;
  display: flex;
  align-items: center;
  /* text-align: center; */
}

.post-widget-card.ant-card .ant-card-body {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  height: 100%;
  width: 100%;
  border-radius: 8px;
}

.item-grid-overlay:before {
  content: "";
  position: absolute;
  top: 5%;
  left: 50%;
  width: 80%;
  height: 5%;
  background: linear-gradient(180deg, #e3ff93 0%, #f5ffd9 0.01%, #b0de2d 100%);
  pointer-events: none;
  z-index: 999;
  border-radius: 18px;
  transform: translate(-50%, 0);
}

.post-widget-spin {
  position: absolute;
  /* color: white !important; */
  top: calc(50% - 12px);
  /* background: rgba(255,255,255,0.5); */
  border: 0.5px none;
  /* border-image: repeating-linear-gradient(30deg, #4d9f0c, #9198e5, #4d9f0c 20px) 60; */
  /* border-style: dashed; */
  left: calc(50% - 18px);
  border-radius: 200px;
  width: 22px;
  height: 22px;
  padding: 0;
  margin: 0;
  /* padding-top: 9px; */
}

.noPointerEvents,
.noPointerEvents img {
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;
  -webkit-touch-callout: none !important;
  -webkit-user-select: none !important;
  -webkit-user-drag: none;
  user-drag: none;

  /* pointer-events: none; */
}

/* Iphone dialog */
.dialog-mask {
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
  color: #000;
}

.dialog-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.dialog-container {
  width: 270px;
  margin: 0px auto;
  background-color: white;
  border-radius: 14px;
  transition: all 0.3s ease;
  font-family: "NewfoundationWhyte-Regular";
  font-weight: 300;
  line-height: 128%;
}

.dialog-header {
  padding: 20px 30px 0 30px;
  text-align: center;
  font-weight: 700;
  text-transform: capitalize;
  font-size: 18px;
}

.dialog-body {
  padding: 10px 30px 20px 30px;
  text-align: center;
  font-size: 14px;
  border-bottom: 0.5px solid hsla(240, 6%, 25%, 0.36);
}

.dialog-footer {
  display: grid;
  grid-auto-flow: column;
  grid-template-column: repeat(2, 1fr);
}

.dialog-default-button {
  background-color: transparent;
  border: 0;
  box-shadow: none;
  cursor: pointer;
  transition: 0.3s ease;
  font-size: 18px;
  color: #007aff;
  padding: 15px 0;
  border-right: 0.5px solid hsla(240, 6%, 25%, 0.36);
  outline: none;
  width: calc(270px / 2);
}

.dialog-default-button-wide {
  width: 100% !important;
}

.dialog-default-button:hover {
  background-color: hsla(0, 0%, 0%, 0.1);
}

.dialog-default-button:last-child {
  border-right: none;
  border-bottom-right-radius: 14px;
}

.dialog-default-button:first-child {
  border-bottom-left-radius: 14px;
}

/* Iphone notification*/
.ant-notification-notice {
  padding: 0;
  box-shadow: none;
  background: none;
}

.native-notification {
  display: flex;
  align-items: center;
  padding: 12px;
  background-color: rgb(0 0 0 / 60%);
  border-radius: 25px;
  height: 82px;
}

.native-notification-icon {
  width: 24px;
  height: 24px;
  margin-right: 12px;
}

.native-notification-content {
  flex-grow: 1;
}

.native-notification-title {
  font-size: 16px;
  font-weight: bold;
  color: #ffffff;
}

.native-notification-body {
  font-size: 14px;
  color: #ffffff;
}

.ant-radio-button-wrapper::before {
  width: 0 !important;
}

.ant-modal .ant-modal-content {
  border-radius: 18px;
  border: 1px #efefef;
  padding: 60px 80px 50px;
}

@media (max-width: 991px) {
  .ant-modal .ant-modal-content {
    padding: 20px 20px 20px 20px;
  }
}

.ant-select-multiple .ant-select-selector {
  padding: 8px;
  border-radius: 18px;
  border: 1px solid #ebebeb;
}

.ant-select-multiple .ant-select-selection-search {
  margin-inline-start: 3px !important;
}

.user-folder-select .ant-select-selector .ant-select-selection-search .ant-select-selection-search-input {
  height: 45px !important;
}

.user-folder-select .ant-select-selector .ant-select-selection-item {
  display: flex;
}

.user-folder-select .ant-select-selector .ant-select-selection-placeholder {
  line-height: 45px;
}

.user-folder-select .ant-select-selector,
.user-folder-select:hover .ant-select-selector {
  border: none !important;
  box-shadow: none !important;
  height: 45px !important;
  border-radius: 18px !important;
}

.search-box .ant-select-selection-placeholder {
  line-height: 45px !important;
}

.search-box .ant-select-selector .ant-select-selection-search .ant-select-selection-search-input,
.search-box .ant-select-selector,
.ant-select-dropdown .ant-select-item-option {
  height: 45px !important;
  border-radius: 10px;
  gap: 10;
}

.profile-tag-select
  .ant-select-selector
  .ant-select-selection-search
  .ant-select-selection-search-input,
.profile-tag-select .ant-select-selector {
  height: 56px !important;
  width: 365px !important;
  border-radius: 18px !important;
  border: 1px solid rgb(217, 217, 217) !important;
  text-align: left;
  padding: 16px !important;
  font-family: "NewfoundationWhyte-Regular", sans-serif;
  font-weight: 300;
}

.profile-tag-select .ant-select-selection-item {
  line-height: unset !important;
}

.ant-select-item-option-content {
  display: flex !important;
  flex-direction: column !important;
  justify-content: center !important;
}

.ant-select-dropdown .ant-select-item-option {
  margin: 0 5 px;
}

.ant-select-dropdown .ant-select-item-option:hover {
  background-color: #f6f6f6 !important;
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover,
.ant-radio-button-wrapper:hover,
.ant-radio-button-wrapper-checked {
  background-color: #f8f8f8 !important;
}

.connect-page {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.auth-button {
  /* transform: translateY(-84px); */
  /* opacity: 0;
  animation: slideDown 0.5s ease forwards; */
  height: 50px;
  width: 250px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px;
  border-radius: 18px;
  background-color: rgb(255, 255, 255);
  border: 1px solid rgba(255, 255, 255, 0);
}

.auth-button:hover {
  border: 1px solid rgba(222, 222, 222, 1) !important;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 0px 0px;
}

.auth-button-wrapper {
  height: 58px;
  width: 270px;
  padding: 4px 10px;
}

/* .auth-button:nth-child(2) {
  animation-delay: 0s;
}

.auth-button:nth-child(3) {
  animation-delay: 0.2s;
}

.auth-button:nth-child(4) {
  animation-delay: 0.4s;
}

@keyframes slideDown {
  to {
    opacity: 1;
    transform: translateY(0);
  }
} */

/*ONBOARDING*/
/* Basic styles for the entire page */
.signup-page {
  display: flex;
  height: 100vh;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  position: relative;
}

/* Left Sidebar */
.left-sidebar {
  position: absolute;
  top: 20px;
  left: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.top-sidebar {
  position: absolute;
  top: 20px;
  left: 50%;
  transform: translate(-50%, 0);
}

.sidebar-btn {
  border-radius: 18px;
  box-shadow: 0 1px 6px 1px #00000014;
  padding: 13px;
  height: 45px;
  width: 54px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: none;
  cursor: pointer;
}

.menu-feed {
  padding: 6px !important;
}

/* Central Signup Box */
.signup-box {
  background-color: white;
  border-radius: 20px;
  padding: 40px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  text-align: center;
  width: 300px;
}

.signup-box h2 {
  margin-bottom: 20px;
  font-weight: 500;
}

.signup-options {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.signup-btn {
  padding: 10px 20px;
  border-radius: 10px;
  border: 1px solid #e0e0e0;
  background-color: white;
  cursor: pointer;
  font-size: 16px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
  transition: background-color 0.2s ease;
}

.signup-btn:hover {
  background-color: #f0f0f0;
}

/* Bottom Left Buttons */
.bottom-buttons {
  position: absolute;
  bottom: 20px;
  left: 20px;
  display: flex;
  gap: 5px;
}

.bottom-buttons-mobile {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translate(-50%, 0);
  display: flex;
  gap: 10px;
}

.bottom-btn {
  padding: 0 18px;
  border-radius: 18px;
  background-color: rgb(255, 255, 255);
  border: none;
  cursor: pointer;
  height: 45px;
  font-family: "NewfoundationWhyte-Regular", sans-serif;
  font-weight: 300;
  font-size: 11.5px;
  letter-spacing: 0.5px;
}

.bottom-btn.signup {
  box-shadow: rgba(0, 0, 0, 0.08) 0px 1px 6px 1px;
  opacity: 1;
}

.bottom-btn.login {
  background-color: transparent;
  color: black;
}

.profile-action-button {
  border: 1px solid transparent;
}

.profile-action-button:hover {
  border: 1px solid rgb(222, 222, 222, 1) !important;
  transition: border-color 0.5s;
}

.background-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  filter: blur(33px);
  /* Adjust the blur level here */
  z-index: 0;
}

.background-video-without-blur {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  filter: blur(0px);
  /* Adjust the blur level here */
  z-index: 0;
}

.onboarding-dialog {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  box-shadow: 0 0 12px -2px #0000001a;
  padding: 70px 100px;
  border-radius: 18px;
  height: 100%;
  width: 100%;
  gap: 49px;
}

@media (max-width: 991px) {
  .onboarding-dialog {
    padding: 0px;
    padding-top: 70px;
    padding-bottom: 70px;
  }
}

.onboarding-dialog-title,
.onboarding-dialog-title>div {
  font-family: "NewfoundationWhyte-Book", sans-serif !important;
  font-size: 23px;
  font-weight: 200;
}

.content {
  position: absolute;
}

.content-mobile {
  position: absolute;
  padding: 20px;
  width: 100%;
}

.prompt-suggestion {
  min-height: 47px;
  background-color: rgb(241, 241, 241);
  padding: 15px;
  display: flex;
  flex: 1 1 calc(50% - 10px);
  /* Buttons take up 50% of container width, minus the gap */
  max-width: calc(50% - 10px);
  align-items: center;
  justify-content: center;
  border-radius: 18px;
  cursor: pointer;
  font-family: "NewfoundationWhyte-Regular";
  font-weight: 300;
  font-size: 14px;
}

.prompt-suggestion:hover {
  background-color: rgb(224, 224, 224) !important;
  transition: background-color 0.5s;
}

/*END ONBOARDING*/

.leaderboard-list:hover {
  background-color: rgba(0, 0, 0, 0.03);
}

.leaderboard-filter-button {
  font-size: 12px;
  border: none;
  box-shadow: none;
  padding: 0;
}

.leaderboard-filter-button-active {
  text-decoration: underline;
  font-size: 12px;
  border: none;
  border-bottom: 1px solid;
  border-radius: 0;
  box-shadow: none;
  padding: 0;
  height: auto;
}

.leaderboard-filter-button:hover,
.leaderboard-filter-button-active:hover {
  color: #a6a6a6 !important;
  border-color: #a6a6a6 !important;
}

.text-media-component-wrapper :not(:not(h1, h2, h3, h4, h5, p)) {
  margin-top: 1.5em;
}
.text-media-component-wrapper h1 {
  font-size: 20pt;
}
.text-media-component-wrapper :not(h1, h2, h3, h4, h5, p) {
  font-size: 16px;
  line-height: 142%;
  font-family: "NewfoundationWhyte-Book" !important;
  margin: 0;
  line-break: auto;
  font-weight: 200;
  white-space: normal;
  word-break: normal;
  max-width: 100%;
}

.text-media-component-wrapper .prompt-suggestion {
  font-size: 14px !important;
}

.selectable-tags {
  padding: 0 15px;
  height: 45px !important;
  border-radius: 18px;
  font-size: 11.5px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.ant-tag-checkable-checked {
  border: 1px solid rgb(235, 235, 235);
  background: rgb(255, 255, 255) !important;
  color: black;
}

.selectable-tags-wrapper .ant-form-item-control-input-content {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 5px;
}

#editProfile_username_help {
  text-align: left;
}